<template>
	<div
		:class="[
			'offer-item',
			{
				'offer-item--collapse': isCollapse,
				'offer-item--manager': isChatManager,
				'offer-item--active': eventIsActive,
			},
		]">
		<div
			@click="changeActiveEvent"
			:title="changeActiveEventTitle"
			v-if="isCollapse || (!isCollapse && isCollapsed)"
			class="offer-item__header">
			<h2 class="offer-item__number">
				# {{ number }}
				<p class="offer-item__title">{{ eventInfo.event }}</p>
			</h2>

			<h3 v-if="!isCollapse && (eventInfo.attributes.agent_fio || eventInfo?.attributes.culture || eventInfo.attributes.farmer)">
				<span
					v-if="eventInfo.attributes.agent_fio"
					class="offer-item__title-sub">
					{{ eventInfo.attributes.agent_fio }}
				</span>

				<div
					v-if="eventInfo?.attributes.culture"
					class="offer-item__product-info">
					<p class="offer-item__product">
						{{ eventInfo?.attributes.culture }}
					</p>

					<p
						v-if="eventInfo.attributes.farmer"
						class="offer-item__supplier-name">
						<span>Поставщик </span>{{ eventInfo.attributes.farmer }}
					</p>
				</div>
			</h3>

			<p
				v-if="isCollapse"
				class="offer-item__supplier-name">
				{{ eventInfo?.attributes.warehouse }}
			</p>
		</div>

		<PageChatOrderInfo
			v-if="!isCollapsed && !isCollapse && eventHasAttributes"
			:order="eventInfo"
			:number="number"
			@click="changeActiveEvent"
			:title="changeActiveEventTitle" />

		<button
			v-if="!isCollapse && eventHasAttributes"
			class="offer-item__toggle-collapse"
			@click="toggleCollapse">
			{{ isCollapsed ? "Развернуть" : "Свернуть" }}
		</button>
	</div>
</template>

<script setup lang="ts">
	import { hasAnyEventAttribute } from "~/utils/has-any-event-attribute";
	import type { IEvent } from "@/assets/ts/global/interfaces/event/IEvent";
	import type { ComputedRef } from "vue";

	const {
		activeEventStore,
		activeEventStore: { setActiveEvent },
		generateMessageStore,
	} = useStores();

	const { getActiveEventId } = storeToRefs(activeEventStore);
	const { isAiGenerateMessage } = storeToRefs(generateMessageStore);

	const props = withDefaults(
		defineProps<{
			eventInfo: IEvent;
			isCollapse: boolean;
			isChatManager?: boolean;
			number?: number;
		}>(),
		{
			isChatManager: false,
			number: 0,
		},
	);

	const isCollapsed = ref(true);

	const toggleCollapse = () => (isCollapsed.value = !isCollapsed.value);

	const eventHasAttributes = computed(() => hasAnyEventAttribute(props.eventInfo));

	const changeActiveEvent = () => {
		if (isAiGenerateMessage.value || getActiveEventId.value === props.eventInfo.id) return;

		setActiveEvent({
			...props.eventInfo,
			number: props.number,
			hasAnyAttribute: eventHasAttributes.value,
		});
	};

	const eventIsActive: ComputedRef<boolean> = computed((): boolean => props.eventInfo.id === getActiveEventId.value);

	const changeActiveEventTitle: ComputedRef<string> = computed((): string => (eventIsActive.value ? "" : "Выбрать событие"));
</script>

<style lang="scss">
	@import "offer-item";
</style>
